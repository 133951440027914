import { useCallback, useEffect, useRef, useState } from "react";
import "./event-camera.scss";
import { Memory } from "memory";
import { HOST_URL } from "event-modal";
import axios from "axios";
import {
  EventState,
  EventTypeState,
  SelectedCameraSate,
  SelectedDeviceIdState,
  VideoHeightState,
  WebrtcSessionIdState,
} from "states";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { EventMatching } from "./events-match";
import { CameraSelector } from "devices";
import { Button } from "components";
import { checkCameraAccess } from "utils/camera";
import { CircularProgress } from "@mui/material";
import { useAgoraFace } from "hooks/face-agora";
import { useAgoraWebcam } from "hooks/agora";
import { v4 } from "uuid";

async function startEvent(
  session_id: string,
  event_type: string,
  collection_id: string,
): Promise<any> {
  const url = `https://events.backendly.io/start`;
  
  try {
    const response = await axios.post(url, null, {
      params: {
        session_id,
        event_type,
        collection_id,
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Failed to start event:', error);
    throw error;
  }
}


export const EventCamera = () => {
  const [isCameraOn, setCameraOn] = useState(false);
  const setEvent = useSetRecoilState(EventState);
  // const { start } = useFace();
  const { start: startAgora } = useAgoraFace();
  const videoHeight = useRecoilValue(VideoHeightState);
  const selectedDeviceId = useRecoilValue(SelectedDeviceIdState);
  const [isCameraLoading, setIsCameraLoading] = useState(false);
  const [isCameraAccess, setIsCameraAccess] = useState(false);
  const selectedCamera = useRecoilValue(SelectedCameraSate);
  const setWebrtcSessionId = useSetRecoilState(WebrtcSessionIdState);
  const eventType = useRecoilValue(EventTypeState);
  
  // const [token, setToken] = useState<string>('');
  const [start, setStart] = useState(false);
  const [started, setStarted] = useState<boolean | null>(null);
  const [loaded, setLoaded] = useState(false);
  // const [sessionStarted, setSessionStarted] = useState(false);
  const apiCalled = useRef(false)

  const appId = '967725ed771648708a01ce0058e99b96';

  const { publish, stopPublishing, initialize, cleanup, videoTrack } = useAgoraWebcam(appId);

  useEffect(() => {
    if (/back/gi.test(selectedCamera?.label ?? "")) {
      document.body.classList.add("unset_rotate");
    } else {
      document.body.classList.remove("unset_rotate");
    }
  }, [selectedCamera?.label]);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    return () => {
      cleanup();
      stopPublishing();
    };
  }, []);

  useEffect(() => {
    if (eventType && !apiCalled.current) {
      // startConnection()
    }
    setTimeout(() => {
      setLoaded(true);
    }, 4000)
 }, [])

  const startConnection = useCallback(() => {
    setStarted(false);
    setWebrtcSessionId(Memory.sessionId);
    const collection_id = Memory.eventId;
    apiCalled.current = true
    const uid = v4();
    startEvent(Memory.sessionId, eventType, collection_id).then((data) => {
      const token =  data.token;
      // setToken(token);
      initialize(token, Memory.sessionId, uid, selectedDeviceId).then(async (track) => {
        if (track) {
          setStart(true);
          await publish(track);
        }
      })
      // setSessionStarted(true);
    });
  }, [eventType, initialize, publish, setWebrtcSessionId, selectedDeviceId])
  
  // Classes for styling the canvas and webcam elements
  const canvasClass = "_output_canvas";
  const webcamClass = "_webcam";

  // Callback function to start the webcam and face detection

  useEffect(() => {
    if (start && videoTrack) {
        const internval = setInterval(() => {
        const video = document.getElementById(webcamClass) as HTMLVideoElement;
        const canvasElement = document.getElementById(
          canvasClass
        ) as HTMLCanvasElement;
        const videoWrapper = document.querySelector('.videoCamWrapper');

        if (!video || !canvasElement || !videoWrapper) {
          return
        }

        setCameraOn(true);

        videoTrack.play(video);
        video.muted = true; // optional: mute the video if needed
        video.autoplay = true; // automatically play the video
        video.playsInline = true; // to ensure it works on mobile browsers

        video.style.height = `${videoWrapper.clientHeight}px`;
        video.style.width = `${videoWrapper.clientWidth}px`;

        video.height = videoWrapper.clientHeight;
        video.width = videoWrapper.clientWidth;
        
        console.log("useEffect", videoWrapper, video.height, video.width, canvasElement);

        if (!video.height || !video.width) {
          return
        }
        
        clearInterval(internval);
        startAgora({
          video,
          canvas: canvasElement,
          draw: true,
          numFaces: 20,
          palm: false,
        }).then(() => {
          setStarted(true);
        })
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, videoTrack])

  const onCam = useCallback(() => {
    startConnection();
  }, [startConnection]);

  const onClick = useCallback(async () => {
    const url = `${HOST_URL}/pipelines`;
    axios
      .get(`${url}/events/${Memory.eventId}?type=event`)
      .then(({ data }) => {
        const eventData = data?.data;
        if (eventData) {
          setEvent(eventData);
        }
        console.log("sadasd", eventData);
        //   setQrs(eventData?.qrIds ?? []);
      })
      .catch((err) => {
        console.error("event err", err);
      });
    onCam();
  }, [onCam, setEvent]);

  const Camera = useCallback(() => {
    return (
      <>
        <div>
          <div id="liveView" className="videoView">
            <div
              style={{
                position: "relative",
                width: '100%',
              }}
            >
                {start && (
                <div>
                  <div
                    className="videoCamWrapper"
                    style={{ width: '100vh', height: '52vh' }}
                  >
                    <video
                      ref={videoRef}
                      id={webcamClass}
                      style={{ width: '100%', height: '100%' }}
                    ></video>
                  </div>
                </div>
              )}
              <canvas
                className={canvasClass}
                id={canvasClass}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></canvas>
            </div>
          </div>
        </div>
      </>
    );
  }, [start]);

  const CheckingCameraAccess = async () => {
    setIsCameraLoading(true);
    const resp = await checkCameraAccess();
    setIsCameraLoading(false);
    setIsCameraAccess(resp);
  };

  useEffect(() => {
    CheckingCameraAccess();
  }, []);

  const CameraSettingsOffMessages = {
    Heading: "Camera access required",
    subHeading:
      "It looks like your camera is not enabled, or we do not have permission to access it.",
    instructionOne:
      "Ensure your camera is physically connected(in case of external webcam) and turned on.",
    instructionTwo: "open your browser settings and allow camera permissions.",
  };

  if (isCameraLoading) {
    return (
      <div
        style={{
          minHeight: videoHeight + 20,
          display: "grid",
          placeItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          Camera Loading... <CircularProgress />
        </div>
      </div>
    );
  }
  if (!isCameraAccess) {
    return (
      <div
        style={{
          minHeight: videoHeight + 20,
          display: "grid",
          placeItems: "center",
        }}
      >
        <div className="settings__wrapper">
          <img
            src="https://storage.googleapis.com/satschel-public-assets/images/kyc-frontend-media/cameraSettings.svg"
            alt="camera"
          />
          <p className="settings__wrapper-heading">
            {CameraSettingsOffMessages.Heading}
          </p>
          <p className="settings__wrapper-subheading">
            {CameraSettingsOffMessages.subHeading}
          </p>
          <div className="settings__instuction-notes-box">
            <p>Follow these steps:</p>
            <ul>
              <li>{CameraSettingsOffMessages.instructionOne}</li>
              <li>{CameraSettingsOffMessages.instructionTwo}</li>
            </ul>
          </div>
          <Button
            label={"Refresh"}
            handleClick={() => window.location.reload()}
            type="button__filled button__filled--primary button__large "
          />
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          minHeight: videoHeight + 20,
        }}
      >
        <div>
          <Camera />
        </div>
      </div>
      <div
        style={{
          minWidth: "800px",
          padding: "0px 16px",
        }}
      >
        {isCameraOn ? (
          <EventMatching />
        ) : (
          <div className="center-align">
            {
              loaded ?
              <div className="d-flex align-item-center direction-column space-around gp-8 p-16  w-480">
              {started === false ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "inherit",
                  }}
                >
                  <CircularProgress />
                  <div>Please wait, we are loading the model</div>
                </div>
              ) : (
                <>
                  <CameraSelector />
                  {/* Button to trigger the onCam function */}
                  <Button
                    handleClick={onClick}
                    label={"Enable Webcam"} // Dynamically updating the button label
                    type="button__filled button__filled--primary button__large w-100"
                  />
                </>
              )}
              </div> : <CircularProgress />
            }
            {/* Camera selector component to choose between available cameras */}
            
          </div>
        )}
      </div>
    </>
  );
};
