import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import {
  CameraTypeState,
  EventTypeState,
  SelectedCameraSate,
  VideoHeightState,
  WebrtcSessionIdState,
} from "states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import AgoraRTC, { IAgoraRTCRemoteUser } from "agora-rtc-react";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Memory } from "memory";
import { DroneEventMatching } from "./drone-events-match";
import { useDroneFace } from "hooks/drone-face";
import "./drone-event-camera.scss";

const appId = '967725ed771648708a01ce0058e99b96';
const channel = 'dronestreaming';
const parmanentToken = '006967725ed771648708a01ce0058e99b96IABY7e+1Xk9o7MF8B3q9f1EX8dt/2wOIuPosDyd7AV95LoY9gjEAAAAAIgBbQ3AU7ipXZwQAAQDuDDdpAgDuDDdpAwDuDDdpBADuDDdp'

async function startDroneEvent(
  session_id: string,
  event_type: string,
  collection_id: string
): Promise<any> {
  const url = `https://devents.backendly.io/start`;
  
  try {
    const response = await axios.post(url, null, {
      params: {
        session_id,
        event_type,
        collection_id,
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Failed to start event:', error);
    throw error;
  }
}

const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
// client.enableDualStream();

export const DroneEventCamera = () => {
  const selectedCamera = useRecoilValue(SelectedCameraSate);
  const setWebrtcSessionId = useSetRecoilState(WebrtcSessionIdState);
  const eventType = useRecoilValue(EventTypeState);
  const videoHeight = useRecoilValue(VideoHeightState);
  const [cameraType, setCameraType] = useRecoilState(CameraTypeState);
  
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [token, setToken] = useState<string>('');
  const [start, setStart] = useState(false);
  const [started, setStarted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const apiCalled = useRef(false)
  
//   useEffect(() => {
//    const search = window.location.search;
//     if (search) {
//         const params = new URLSearchParams(search);
//         const tokenPassed = params.get("token");
//         if (tokenPassed) {
//           const localToken = tokenPassed.replaceAll(" ", "+");
//           setToken(localToken)
//         }
//       }
//  }, [])

  const { start: startDrone } = useDroneFace();

  const init = useCallback(async (providedToken: string) => {
    try {
      // Initialize client event listeners
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === 'video') {
          // client.setRemoteVideoStreamType(user.uid, 1);
          setUsers((prevUsers) => [...prevUsers, user]);
        }
      });

      // client.on("network-quality", (stats) => {
      //   console.log("network-quality", stats)
      //   if (stats.uplinkNetworkQuality > 3 || stats.downlinkNetworkQuality > 3) {
      //     // Adjust stream type based on network quality
      //     client.remoteUsers.forEach((user) => {
      //       client.setRemoteVideoStreamType(user.uid, 1); // Switch to low-quality if network is poor
      //     });
      //   }
      // });

      client.on('user-unpublished', (user) => {
        setUsers((prevUsers) => prevUsers.filter((User) => User.uid !== user.uid));
      });

      console.log("token", providedToken);
      // Join the channel
      await client.join(appId, channel, providedToken ?? parmanentToken);

      setStart(true);
    } catch (error: any) {
      if (error.message.includes("token timeout")) {
        // Handle token expiration, e.g., prompt to refresh or fetch a new token
        console.error("Token expired. Please generate a new token and try again.");
        // await client.join(appId, channel, parmanentToken);
      } else {
        console.error("Agora RTC Error: ", error);
      }
    }
  }, []);

  const startConnection = useCallback(() => {
    setStarted(true)
    setWebrtcSessionId(Memory.sessionId);
    const collection_id = Memory.eventId;
    apiCalled.current = true
    startDroneEvent(Memory.sessionId, eventType, collection_id).then((data) => {
      const token =  data.rtc_token;
      setToken(token);
      init(token);
      setSessionStarted(true);
    });
  }, [eventType, init, setWebrtcSessionId])

  useEffect(() => {
    if (eventType && !apiCalled.current) {
      // startConnection()
    }
    setTimeout(() => {
      setLoaded(true);
    }, 4000)
 }, [])

  useEffect(() => {
    // init();
    return () => {
      // Cleanup on component unmount
      client.leave();
    };
  }, []);

  useEffect(() => {
    if (start) {
      const internval = setInterval(() => {
        const video = document.getElementById('videoCam') as HTMLVideoElement;
        const canvasElement = document.getElementById(
          canvasClass
        ) as HTMLCanvasElement;
        const videoWrapper = document.querySelector('.videoCamWrapper');

        if (!video || !canvasElement || !videoWrapper) {
          return
        }

        video.style.height = `${videoWrapper.clientHeight}px`;
        video.style.width = `${videoWrapper.clientWidth}px`;

        video.height = videoWrapper.clientHeight;
        video.width = videoWrapper.clientWidth;
        
        console.log("useEffect", videoWrapper, video.height, video.width, canvasElement);

        if (!video.height || !video.width) {
          return
        }
        
        clearInterval(internval);
        startDrone({
          video,
          canvas: canvasElement,
          draw: true,
          numFaces: 20,
          palm: false,
        })
      }, 2000);

    //  setTimeout(() => {
    //     const video = document.getElementById('videoCam') as HTMLVideoElement;
    //     const canvasElement = document.getElementById(
    //       canvasClass
    //     ) as HTMLCanvasElement;
    //     if (!video || !canvasElement) {
    //       return
    //     }
    //     console.log("useEffect",video.videoHeight, video.videoWidth, video.height, video.width, canvasElement)
    //     startDrone({
    //       video,
    //       canvas: canvasElement,
    //       draw: true,
    //       numFaces: 10,
    //       palm: false,
    //     })
    //   }, 2000)
    }
  }, [start])
  
  useEffect(() => {
    if (/back/gi.test(selectedCamera?.label ?? "")) {
      document.body.classList.add("unset_rotate");
    } else {
      document.body.classList.remove("unset_rotate");
    }
  }, [selectedCamera?.label]);

  // Classes for styling the canvas and webcam elements
  const canvasClass = "_output_canvas";

  const droneUser = useMemo(() => 
    users.length ? [users[users.length - 1]] : [], 
  [users]
  );

  const Camera = useCallback(() => {
    return (
      <>
        <div
          style={{
            position: "relative",
            width: '100%',
          }}
        >
            {start && (
            <div>
              {droneUser.map((user) => (
              <div
                className="videoCamWrapper"
                key={user.uid}
                id={`remote-stream-${user.uid}`}
                style={{ width: '100vh', height: '52vh' }}
              >
                <video
                id="videoCam"
                  style={{ width: '100%', height: '100%' }}
                  ref={(el) => {
                    if (el) {
                      el.muted = true; // optional: mute the video if needed
                      el.autoplay = true; // automatically play the video
                      el.playsInline = true; // to ensure it works on mobile browsers
                      user.videoTrack && user.videoTrack.play(el);
                    }
                  }}
                ></video>
              </div>
            ))}
            </div>
          )}
          <canvas
            className={canvasClass}
            id={canvasClass}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
            }}
          ></canvas>
        </div>
      </>
    );
  }, [droneUser, start]);

  const handleCameraTypeChange = useCallback((event: any) => {
    const cameraType = event.target?.value;
  
    setCameraType(cameraType);
  }, [setCameraType]);

  return (
    <>
      <div
       style={{
        minHeight: videoHeight + 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      >
        {
          sessionStarted ?
          <div>
            <Camera />
            <DroneEventMatching />
          </div> : 
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              {
                started ? <CircularProgress /> : 
                <div>
                  {
                    loaded ?
                    <>
                      <div style={{paddingBottom: 20}}>
                        <FormControl fullWidth>
                          <InputLabel id="camera-type-label">Camera Type</InputLabel>
                        
                          <Select
                            labelId="camera-type-label"
                            value={cameraType}
                            onChange={handleCameraTypeChange}
                            label="Camera Type"
                            sx={{ height: 40 }}
                            MenuProps={{
                              PaperProps: {
                                sx: { maxHeight: 200 }  // Optional: Adjust max height of the dropdown menu
                              }
                            }}
                          >
                            {['entry', 'exit'].map(type => (
                              <MenuItem key={type} value={type}>
                                {type.slice(0, 1).toUpperCase()}{type.slice(1)} Camera
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Button style={{width: "100%"}} variant='contained' onClick={startConnection}>
                        Start
                      </Button>
                    </>
                  :  <CircularProgress />
                }
                </div>
              }
          </div>
        }
      </div>
    </>
  );
};
