import { Fragment, useMemo, useState } from "react";
import { EventHeader } from "../event-header";
import { PendingProfile } from "../pending-profile/pending-profile";
import { UnknownUserState } from "states";
import { useRecoilState } from "recoil";
import Modal from "components/new-modal/modal";

import { Input, Button } from "components";
import { dummyUrl } from "./url";

export const PendingProfiles = () => {
  const isDrone = /drone/gi.test(window.location.pathname)
  const [unknownUsersList, setUnknownUsersList] =
    useRecoilState(UnknownUserState);

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => setShowModal(true);

  const unknownUsers = useMemo(() => {
    // if (isDrone && unknownUsersList.length) {
    //   return [unknownUsersList[0]].map((user) => ({
    //     img: `data:image/jpeg;base64, ${dummyUrl}`,
    //     ...user,
    //   }));
    // }
    return unknownUsersList.map((user) => ({
      img: `data:image/jpeg;base64, ${user.cropped_image}`,
      ...user,
    }));
  }, [isDrone, unknownUsersList]);

  const removeHandleDeny = (index: number) => {
    setUnknownUsersList((prev) => {
      const newObj = structuredClone(prev);
      //@ts-ignore
      return newObj.filter((_, i) => index !== i);
    });
  };

  return (
    <Fragment>
      <EventHeader label="Unregistered List" className="pl-16 pr-16" />
      <div className="event-wrapper__unregistered-profiles">
        {unknownUsers?.length > 0 ? (
          unknownUsers.map((el, index) => (
            <PendingProfile
              key={`${index}-pending`}
              {...el}
              index={index}
              className="sm-100"
              handleClick={handleModalOpen}
              handleDeny={removeHandleDeny}
            />
          ))
        ) : (
          <div className="content-center mt--100">
            <div className="event-empty-list">
              <img
                loading="eager"
                src="https://storage.googleapis.com/satschel-assets-public/images/simplici-media/noData.svg"
                alt="noData.svg"
                className="empty-table__image"
              />
              <div className="event-empty-list__message">
                 No Unregistered List found
                <div className="event-empty-list__sub-message">
                  Sorry, no results were found.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modal for Approving */}
      <Modal
        isOpen={showModal}
        closeModal={handleModalClose}
        className="approved-modal"
        modalName="approved modal"
        title={
          <div className="approved-modal__header">
            <div className="approved-modal__label">
              Approve Using Ticket Number
            </div>
            <div className="approved-modal__sublabel">
              Please enter the ticket number in the input field below.
            </div>
          </div>
        }
      >
        {/* Modal Content */}
        <div style={{ padding: 16 }}>
          <Input
            label="Ticket Number"
            inputType="number"
            placeholder="Enter Ticket Number"
            handleChange={() => {}}
          />
        </div>

        {/* Modal Footer */}
        <div className="event-wrapper__btn-wrapper">
          <Button
            label="Cancel"
            handleClick={handleModalClose}
            type="button__filled button__filled--secondary button__large button-cancel"
          />
          <Button
            label="Approve"
            handleClick={() => {}}
            type="button__filled button__filled--primary button__large button-ok"
          />
        </div>
      </Modal>
    </Fragment>
  );
};
