import { useState, useCallback } from "react";
import AgoraRTC, { ICameraVideoTrack, CameraVideoTrackInitConfig } from "agora-rtc-sdk-ng";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

export const useAgoraWebcam = (appId: string) => {
  const [videoTrack, setVideoTrack] = useState<ICameraVideoTrack | null>(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const initialize = useCallback(async (token: string, channel: string, uid: string | number, cameraId?: string) => {
    try {
      // Join the channel directly; no need for client initialization
      await client.join(appId, channel, token);

      // Create a video track with Full HD resolution and higher bitrate
      const videoTrackOptions: CameraVideoTrackInitConfig = {
        encoderConfig: {
            width: {ideal: 1920}, // Full HD width
            height: {ideal: 1080}, // Full HD height
            frameRate: 60, // 30 frames per second
            bitrateMax: 10000, // Bitrate in kbps (increase for higher quality)
            bitrateMin: 2000, // Bitrate in kbps (increase for higher quality)
        },
        cameraId,
        // optimizationMode: "detail",
      };

      // Create a video track
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack(videoTrackOptions);
      setVideoTrack(localVideoTrack);
      console.log("Agora client joined channel and video track created.");
      return localVideoTrack;
    } catch (err) {
      console.error("Error initializing Agora client:", err);
      setError((err as Error).message);
      return null;
    }
  }, [appId]);

  const publish = useCallback(async (vidTrack: ICameraVideoTrack) => {
    const track = vidTrack ?? videoTrack;
    if (!track) {
      setError("Video track is not available.");
      return;
    }
    try {
    console.log("------publishtoken 2", track)
      console.log("Webcam track published")
      await client.publish([track]);
      console.log("Webcam track published.");
      setIsPublishing(true);
    } catch (err) {
      console.error("Error publishing webcam track:", err);
      setError((err as Error).message);
    }
  }, [videoTrack]);

  const stopPublishing = useCallback(async () => {
    if (isPublishing && videoTrack) {
      try {
        await client.unpublish([videoTrack]);
        console.log("Webcam track unpublished.");
        setIsPublishing(false);
      } catch (err) {
        console.error("Error unpublishing webcam track:", err);
        setError((err as Error).message);
      }
    }
  }, [isPublishing, videoTrack]);

  const cleanup = useCallback(() => {
    videoTrack?.close();
    client.leave().then(() => console.log("Left the channel and cleaned up."));
  }, [videoTrack]);

  // useEffect(() => {
  //   initialize();

    // return () => {
    //   cleanup();
    // };
  // }, []);

  return { initialize, videoTrack, publish, stopPublishing, isPublishing, error, cleanup };
};