import * as vision from "@mediapipe/tasks-vision";
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { ModelLoadedState, QrShowedState, ShowPalmMatchState } from "../states";
import { Memory } from "../memory";
import { worker } from "../App";
import { QRCode } from "jsqr";
import AgoraQrScanner from "./agora-scanner";
import facedetection from '@mediapipe/face_detection';
import jsQR from 'jsqr';

const { FaceLandmarker, FilesetResolver, DrawingUtils } =
  vision;


const style = {
  lineColor: "#fff",
  lineWidth: 8,
};

let isStarted = false

const getColor = (x: number, y: number) => {

  const foundUser = Object.keys(Memory.users ?? {}).find(userId => {
    const user = Memory.users[userId];
    const {BoundingBox} = user;
    const {Height, Left, Top, Width} = BoundingBox

    // Calculate the bounding box edges
    const xMin = Left;
    const xMax = Left + Width;
    const yMin = Top;
    const yMax = Top + Height;

    // Example MediaPipe landmark coordinates
    const landmarkX = x; // Replace with actual value
    const landmarkY = y; // Replace with actual value

    // Check if landmark is within the bounding box
    return (landmarkX >= xMin && landmarkX <= xMax) && (landmarkY >= yMin && landmarkY <= yMax);
  });

  if (foundUser) {
    return /unknown/gi.test(foundUser) ? 'red': "#90EE90";
  }
  return '#C0C0C070';
}

export const useAgoraFace = () => {
  const faceLandmarker = useRef<vision.FaceLandmarker>();
  const faceDetector = useRef<facedetection.FaceDetection>();
  const webcamRunning = useRef(false);
  const videoWidth = useRef<number>()
  const lastVideoTime = useRef(-1);
  const faceLandmarkerResults = useRef<vision.FaceLandmarkerResult>();
  const gestureRecognizerResults = useRef<vision.GestureRecognizerResult>();

  const predictionsResults = useRef<cocoSsd.DetectedObject[]>();
  const canvasElement = useRef<HTMLCanvasElement>();
  const videoElement = useRef<HTMLVideoElement>();
  const setShowPalmMatch = useSetRecoilState(ShowPalmMatchState);
  const setQrShowed = useSetRecoilState(QrShowedState);
  const setModelLoaded = useSetRecoilState(ModelLoadedState);
  // const memoryCanvas = useRef(document.createElement('canvas')) 
  const memoryCanvas = useRef<OffscreenCanvas>() 
  
  // Draw plus sign parameters
  const callbackFunc = useRef<Function>();

  // useEffect(() => {
  //   worker.onmessage = (e) => {
  //     const qrData = e.data?.data;
  //     qrCodeResults.current = qrData;
  //   };
  // }, [])

  const drawLine = useCallback((ctx: any, begin: any, end: any, color: string) => {
    ctx.beginPath();
    ctx.moveTo(begin.x, begin.y);
    ctx.lineTo(end.x, end.y);
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.stroke();
  }, [])

  const drawCanvas = useCallback(async () => {
    const canvasCtx = canvasElement.current?.getContext("2d");
    const canvas = canvasElement.current;
    const video = videoElement.current;

    // videoCamWrapper
    if (!canvasCtx || !video || !canvas) {
      return;
    }
    
    const radio = video.clientHeight / video.clientWidth;
    videoWidth.current = video.clientWidth;

    if (isNaN(radio)) {
      return;
    }

    const drawingUtils = new DrawingUtils(canvasCtx);
    video.style.width = videoWidth.current + "px";
    video.style.height = videoWidth.current * radio + "px";
    canvas.style.width = videoWidth.current + "px";
    canvas.style.height = videoWidth.current * radio + "px";
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    if (!memoryCanvas.current) {
      memoryCanvas.current = new OffscreenCanvas(video.videoWidth, video.videoHeight);
    }

    const memoryContext = memoryCanvas.current?.getContext('2d');

    if (!memoryContext) {
      return;
    }

    try {
      await faceDetector.current?.send({image: video});

      // Draw the current video frame onto the canvas
      memoryContext.drawImage(video, 0, 0, canvas.width, canvas.height);
      // const imageData = memoryContext.getImageData(0, 0, canvas.width, canvas.height);
    
      // Decode the QR code using jsQR
      // const code = jsQR(imageData.data, canvas.width, canvas.height, {
      //   inversionAttempts: "attemptBoth",
      // });

      // console.log("code", code);
      // if (code) {
      //   const qrValue = code?.data
      //   const isValid = !!Memory.eventUserList.find(user => user.sessionId === qrValue);
        
      //   Memory.showedQrs[qrValue] = isValid;
      //   setQrShowed(prev=> {
      //     const cloned = JSON.parse(JSON.stringify(prev));
      //     cloned[qrValue] = isValid
      //     return cloned;
      //   });

      //   let color = '#39FF14CC';

      //   if (isValid) {
      //     // document.body.classList.remove("invalid-qr");
      //     // document.body.classList.add("success-qr");    
      //   } else {
      //     color = '#FF0000CC'
      //     // document.body.classList.remove("success-qr");    
      //     // document.body.classList.add("invalid-qr");    
      //   }

      //   // Move the top corners up by 20 pixels
      //   const adjustedTopLeftCorner = { x: code.location.topLeftCorner.x, y: code.location.topLeftCorner.y - 4 };
      //   const adjustedTopRightCorner = { x: code.location.topRightCorner.x, y: code.location.topRightCorner.y - 4 };
        
      //   drawLine(canvasCtx, adjustedTopLeftCorner, adjustedTopRightCorner, color);
      //   drawLine(canvasCtx, adjustedTopRightCorner, code.location.bottomRightCorner, color);
      //   drawLine(canvasCtx, code.location.bottomRightCorner, code.location.bottomLeftCorner, color);
      //   drawLine(canvasCtx, code.location.bottomLeftCorner, adjustedTopLeftCorner, color);
      // } else {
      //   //
      // }
    } catch(err) {
      // 
    }

    // Now let's start detecting the stream.
    let startTimeMs = performance.now();
    if (lastVideoTime.current !== video.currentTime) {
      lastVideoTime.current = video.currentTime;
      try {
        faceLandmarkerResults.current = faceLandmarker.current?.detectForVideo(
          video,
          startTimeMs,
        );
      } catch(err) {
        console.error("faceLandmarkerResults.err", err)
      }
    }

    if (!gestureRecognizerResults.current?.landmarks?.length) {
      style.lineColor = "#fff";
      setShowPalmMatch(false);
    } else {
      setShowPalmMatch(true);
    }

    Memory.faceLength = faceLandmarkerResults.current?.faceLandmarks.length ?? 0
    // emit
    if (faceLandmarkerResults.current?.faceLandmarks) {
      for (const landmarks of faceLandmarkerResults.current.faceLandmarks ??
        []) {

        // const color = Memory.faceColor;
        const color = getColor(landmarks[45].x, landmarks[45].y);

        // Draw face landmarks
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_TESSELATION,
          { color, lineWidth: 1 }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LIPS,
          {
            color,
          }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
          { color }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
          { color }
        );
      }
    }

    callbackFunc.current?.(
      faceLandmarkerResults.current,
      gestureRecognizerResults.current,
      predictionsResults.current,
    );

    // Call this function again to keep predicting when the browser is ready.
    if (webcamRunning.current === true) {
      window.requestAnimationFrame(drawCanvas);
    }
  }, [setShowPalmMatch]);

  const enableCam = useCallback(
    (draw?: boolean, deviceId?: string) => {
      if (videoElement.current) {
        webcamRunning.current = true;
        if (draw) {
            if (videoElement.current) {
              // console.log("result", videoElement.current);
              const qrscanner = new AgoraQrScanner(videoElement.current, (result) => { 
                // console.log("result", result);
                const qrValue = result?.data
                const isValid = !!Memory.eventUserList.find(user => user.sessionId === qrValue);
                
                Memory.showedQrs[qrValue] = isValid;
                setQrShowed(prev=> {
                  const cloned = JSON.parse(JSON.stringify(prev));
                  cloned[qrValue] = isValid
                  return cloned;
                });

                if (isValid) {
                  document.body.classList.remove("invalid-qr");
                  document.body.classList.add("success-qr");    
                } else {
                  document.body.classList.remove("success-qr");    
                  document.body.classList.add("invalid-qr");    
                }
              }, {
              
                highlightScanRegion:true,
                highlightCodeOutline:true,
                maxScansPerSecond: 144,
              })
              console.log("result", qrscanner);

              if (!isStarted) {
                isStarted = true
                console.log("result isStarted", isStarted);
                qrscanner.start() 
              }
            }
            drawCanvas()
        }
      }
    },
    [drawCanvas, setQrShowed]
  );

  // Before we can use HandLandmarker class we must wait for it to finish
  // loading. Machine Learning models can be large and take a moment to
  // get everything needed to run.
  const start = useCallback(
    async ({
      video,
      canvas,
      draw = true,
      deviceId,
      onResults,
      palm = true,
      numFaces = 1
    }: {
      video: HTMLVideoElement;
      canvas: HTMLCanvasElement;
      draw?: boolean;
      deviceId?: string;
      numFaces?: number;
      palm?: boolean;
      onResults?: (results?: vision.FaceLandmarkerResult) => void;
    }) => {
      setModelLoaded(false);
      videoElement.current = video;
      canvasElement.current = canvas;

      callbackFunc.current = onResults;
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );

      faceLandmarker.current = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: "GPU",
          },
          outputFaceBlendshapes: true,
          runningMode: "VIDEO",
          numFaces,
        }
      );

      const threshold = Memory.threshold ?? 0.45;

      faceLandmarker.current = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: "GPU",
          },
          outputFaceBlendshapes: true,
          runningMode: "VIDEO",
          numFaces,
           minFaceDetectionConfidence: threshold,
           minTrackingConfidence: threshold,
           minFacePresenceConfidence: threshold,
        }
      );

      const faceDetect = new facedetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      });

      faceDetect.setOptions({
        minDetectionConfidence: threshold,
        selfieMode: false,
        model: "full",
      })

      faceDetector.current = faceDetect;

      faceDetector.current.onResults(results => {
        // const color = Memory.faceColor;
        const canvasCtx = canvasElement.current?.getContext("2d");
        if (canvasCtx) {
          const drawutils = new DrawingUtils(canvasCtx);
          for (const result of results.detections) {
            if (result.boundingBox) {
              // console.log('result.landmarks', result.landmarks)
              const color = getColor(result.landmarks[2].x, result.landmarks[2].y);
              // drawutils.drawLandmarks(result.landmarks as any, {
              //   color,
              //   radius: 2,
              //   lineWidth: 5
              // });

              // canvasCtx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
            const box = result.boundingBox;
            // Scale the normalized coordinates to canvas size
            const xCenter = box.xCenter * canvas.width;
            const yCenter = box.yCenter * canvas.height;
            const width = box.width * canvas.width;
            const height = box.height * canvas.height;

            // Calculate the top-left corner of the bounding box
            const x = xCenter - width / 2;
            const y = yCenter - height / 2;

            // Draw the rectangle
            canvasCtx.save(); // Save the current canvas state

            if (box.rotation !== 0) {
              // If there's rotation, apply it
              canvasCtx.translate(xCenter, yCenter);
              canvasCtx.rotate(box.rotation);
              canvasCtx.translate(-xCenter, -yCenter);
            }

            canvasCtx.strokeStyle = color;
            canvasCtx.lineWidth = 4;
            canvasCtx.strokeRect(x, y, width, height);

            // Draw the ID or label
            // canvasCtx.restore(); // Restore the canvas state to undo rotation
            }
          }
          drawutils.close();
        }
      })

      setModelLoaded(true);
      enableCam(draw, deviceId);
    },
    [enableCam, setModelLoaded]
  );

  const draw = useCallback(async () => {
    await drawCanvas();
  }, [drawCanvas]);

  return { start, draw };
};
