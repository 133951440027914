import { useAgoraWebcam } from "hooks/agora";
import React, { useEffect, useRef } from "react";

export const WebcamPublisher = () => {
  const token = "007eJxTYDidNy3AfhLP1Nh19lFX66o3iS/fqryJ+dyP1cX8dcITji5WYLA0Mzc3Mk1NMTc3NDOxMDewSDQwTE41MDC1SLW0TLI0O1sWnt4QyMhwW/g+CyMDBIL4fAypZal5JcUlRamJuZl56QwMAHAzIx0=";
  const appId = '967725ed771648708a01ce0058e99b96';
  const channel = 'eventstreaming';
  const uid = "12345";
  
  const { publish, stopPublishing, isPublishing, error, initialize, cleanup } = useAgoraWebcam(appId);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    initialize(token, channel, uid).then((track) => {
      if (track && videoRef.current) {
        // Ensure we only call `play()` once
        track.play(videoRef.current);
  
        console.log('useeffect--------------')
        // Return cleanup function to stop video
        return () => {
          track.stop();
          track.close();
        };
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{
          width: "640px",
          height: "480px",
          backgroundColor: "black", // Ensure a consistent background
        }}
      ></video>
      <div>
        <button onClick={() => publish} disabled={isPublishing}>
          Start Publishing
        </button>
        <button onClick={stopPublishing} disabled={!isPublishing}>
          Stop Publishing
        </button>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};
