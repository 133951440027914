import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  FaceState,
  ShowPalmMatchState,
  WebrtStatusState,
  WebrtcCompletedState,
  WebrtcSessionIdState,
} from "./states";
import { database } from "./utils/firebase";
import { ref, onValue } from "firebase/database";
import FaceMetaInfo from "./hooks/face-meta";
import { Memory } from "./memory";
import { worker } from "./App";
import ScoreModal from "./score-modal";

// function getRandomNumber() {
//     return ((Math.random() * 100) + 1);
// }

// const checkRegx = /laptop|pc|driving license|id cards|document|phone|portrait|mobile|selfie|screen|monitor|hardware/gi;
// const checkRegx = /laptop|pc|driving license|electronic|id cards|document|phone|mobile|screen|monitor|hardware/gi;
const checkRegx = /driving license|id cards|document|phone|mobile/gi;

function findMostFrequentValue(arr: any[], key: string) {
  // Create an object to store the count of each value
  const countMap: Record<string, number> = {};

  // Count the occurrences of each value
  arr.forEach(obj => {
    const value = obj[key];
    if (countMap[value]) {
      countMap[value]++;
    } else {
      countMap[value] = 1;
    }
  });

  // Find the value with the maximum count
  let maxCount = 0;
  let maxValue = null;

  for (const value in countMap) {
    if (countMap[value] > maxCount) {
      maxCount = countMap[value];
      maxValue = value;
    }
  }
  return maxValue;
}

interface ILabel {
  Confidence: number;
  Name?: "Person";
}

export interface Spoof {
  average_deepface_score: number;
  average_detection_score: number;
  average_disguise_score: number;
  average_liveness_score: number;
  frames: number;
  net_liveness_codes: Netlivenesscodes;
  raw: Raw;
  total_deepfake_score: number;
  total_detection_score: number;
  total_disguise_score: number;
  total_liveness_score: number;
}

export interface Raw {
  duration: number;
  faces: Face[];
  frame_id: number;
}

export interface Face {
  deepfake_score: number;
  detection_score: number;
  disguise_score: number;
  liveness_hint?: string;
  landmarks: Landmark[];
  liveness_code: string;
  liveness_score: number;
  warpedBox: number[];
}

export interface Landmark {
  type: string;
  values: number[];
}

export interface Netlivenesscodes {
  s_disputed: number;
  s_genuine: number;
  s_spoof: number;
}

interface IFaceScore {
  face_score: string;
  palm_score: number;
  phone_detected: number;
  labels: {
    Labels: ILabel[];
  };
  spoof?: Spoof;
}

export const Matching = () => {
  const webrtcSessionId = useRecoilValue(WebrtcSessionIdState);
  const showPalmMatch = useRecoilValue(ShowPalmMatchState);
  
  const [face, setFace] = useRecoilState(FaceState);
  const [palm, setPalm] = useState(0);
  const webrtStatus = useRecoilValue(WebrtStatusState);
  const spoofs = useRef<number[]>([]);
  const totalFrames = useRef<number>(0);
  
  const FRAMES = Memory.frames || Number(localStorage.getItem("frames") || 0);
  const spoofCounter = useRef(0);
  const spoofDetectData = useRef<Spoof[]>([]);
  const framesCounter = useRef(0);
  const counterMatch = useRef(0);
  const beforeFakeScore = useRef<number>();
  const [isSpoof, setIsSpoof] = useState<boolean>();
  const [showModal, setModal] = useState(false);
  const webrtcCompleted = useRecoilValue(WebrtcCompletedState);

  useEffect(() => {
    if (webrtcCompleted) {
      if (Memory.timeout) {
        setModal(true);
      }
    }
  }, [webrtcCompleted])

  const detectSpoof = useCallback((score: number, labels: ILabel[]) => {
    // console.log('a', labels);
    // const hintsAndCode: {code: string, hint: string}[] = [];
    // const liveness_score = spoofDetectData.current.reduce((acc, curr) => {
    //   hintsAndCode.push({
    //     code: curr.raw?.faces?.[0]?.liveness_code ?? '',
    //     hint: curr.raw?.faces?.[0]?.liveness_hint ?? '',
    //   })
    //   return acc + (curr.raw?.faces?.[0]?.liveness_score ?? 0);
    // }, 0) / spoofDetectData.current.length;
    
    // const liveness_hint = findMostFrequentValue(hintsAndCode, 'hint');
    // const liveness_code = findMostFrequentValue(hintsAndCode, 'code');

    // let code = liveness_code ?? '';
    // let liveHint = liveness_hint ?? ''
    // let liveScore = liveness_score ?? 0

    let faceScore = score;
    let code = '';
    let liveScore = 0;

    // const isLive = liveScore >= FaceMetaInfo.THRESHOLD.LIVENESS;
    // let isMoveOrLight = /move|light|genuine/gi.test(liveness_hint ?? '')
    
    // if (!liveHint) {
    //   isMoveOrLight = true;
    // }

    // const isReal = true;
    const {externalDevice, isPersonScoreLow} = Memory.detection;

    const isFake = externalDevice || isPersonScoreLow;
    const _score = beforeFakeScore.current ?? score;
    const isFaceMatch = _score >= FaceMetaInfo.THRESHOLD.FACE_MATCH;

    
    const fakeDevice = labels.find((l) => checkRegx.test(l.Name ?? ""));
  
    console.log("Result: ", {
      fakeDevice: fakeDevice?.Name,
      isFake,
      isFaceMatch,
      counterMatch: counterMatch.current
    })
    
    totalFrames.current += 1;

    if (isFake) {
      spoofs.current.push(100);
      if (beforeFakeScore.current === undefined) {
        beforeFakeScore.current = score;
      }
      code = FaceMetaInfo.TYPES.s_spoof;
      liveScore = 0;
      setIsSpoof(true);
      // faceScore = 0;
      FaceMetaInfo.updateValue('code', code);
      FaceMetaInfo.updateValue('score', liveScore);
      counterMatch.current = 0;
    } else if (fakeDevice) {
      setIsSpoof(true);
      spoofs.current.push(100);
      if (beforeFakeScore.current === undefined) {
        beforeFakeScore.current = score;
      }
      code = FaceMetaInfo.TYPES.s_spoof;
      liveScore = 0;
      // faceScore = 0;
      FaceMetaInfo.updateValue('code', code);
      FaceMetaInfo.updateValue('score', liveScore);
      counterMatch.current = 0;
    } else if ((!isFake || !fakeDevice) && isFaceMatch && counterMatch.current <= 8) {
      setIsSpoof(false);
      if (beforeFakeScore.current !== undefined) {
        faceScore = beforeFakeScore.current;
      }

      if (beforeFakeScore.current === 0 && score === 100) {
        faceScore = score;
      }

      if (counterMatch.current === 8) {
        beforeFakeScore.current = undefined;
      }
      code = '';
    } 
    else {
      setIsSpoof(false);
      code = '';
    }

    // console.log("Result: ", {
    //   externalDevice,
    //   isPersonScoreLow,
    //   faceScore,
    //   _score,
    //   // isReal,
    //   // liveScore,
    //   // liveHint,
    //   // _score,
    // })

    counterMatch.current += 1;
    
    // FaceMetaInfo.updateValue("hint", liveHint);
    FaceMetaInfo.updateValue('code', code);
    FaceMetaInfo.updateValue('score', liveScore);
    
    // console.log("Result: ", {
    //   isReal,
    //   liveness_score,
    //   isMoveOrLight,
    //   liveHint,
    //   score,
    //   faceScore,
    // });

    return faceScore
  }, [])

  // useEffect(() => {
  //   worker.onmessage = (event) => {
  //     const data = event.data
  //     if (data?.result) {
  //       const similarity = data?.result?.FaceMatches?.[0]?.Similarity
  //       console.log(similarity);
  //       if (!similarity) {
  //         setFace(0)
  //       } else {
  //         setFace(similarity)
  //       }
  //     }
  //   };
  // }, [])

  const update = useCallback((data: IFaceScore) => {
    console.log("Data: ", data);
    if (!data) {
      return null
    }
    framesCounter.current += 1;

    if (data.face_score == null && framesCounter.current < 8) {
      return null;
    }

    spoofCounter.current += 1;

    if (data.spoof) {
      spoofDetectData.current.push(data.spoof);
    }

    const score = Number(data.face_score ?? 0) * 100;
    if (!Memory.webrtcConnected) {
      Memory.webrtcConnected = true;
    }

    setPalm((data.palm_score ?? 0) * 100);
    
    if (FRAMES === 0 || spoofCounter.current === FRAMES) {
      const faceScore = detectSpoof(score, data.labels?.Labels ?? [])
      Memory.faceScore = faceScore;
      setFace(faceScore);
      spoofCounter.current = 0;
      spoofDetectData.current = []
    }
    
    // setFakeDevice(data.phone_detected * 100)
    // setLabels(data.labels?.Labels ?? []);
    // updateStarCount(postElement, data);
  }, [detectSpoof])

  useEffect(() => {
    if (webrtcSessionId) {
      // Reference to your data in the Realtime Database

      // socket.addEventListener("message", (event) => {
      //   // console.log("Message from server ", event.data);
      //   // const data: IFaceScore = JSON.parse(event.data)
      //   // update(data);
      // });

      const dataRef = ref(database, webrtcSessionId);

      onValue(dataRef, (snapshot) => {
        const data: IFaceScore = snapshot.val();
        if (data) {
          //
          update(data)
        }
      });

      // Clean up the listener when the component unmounts
      // return () => {
      //   off(dataRef);
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webrtcSessionId]);

  // useEffect(() => {
  //     setInterval(() => {
  //         setFace(getRandomNumber())
  //         setPalm(getRandomNumber())
  //     }, 1000)
  // }, []);

  const faceStyle = useMemo(() => {
    if (face == null) {
      return {};
    }

    if (isSpoof === true) {
      return {
        color: "red",
      };
    }

    if (face < 50) {
      return {
        color: "red",
      };
    } else if (face > 50 && face < 80) {
      return {
        color: "#FFBF00",
      };
    } else if (face > 80) {
      return {
        color: "green",
      };
    }
    return {};
  }, [face, isSpoof]);

  const palmStyle = useMemo(() => {
    if (palm < 50) {
      return {
        color: "red",
      };
    } else if (palm > 50 && palm < 80) {
      return {
        color: "#FFBF00",
      };
    } else if (palm > 80) {
      return {
        color: "green",
      };
    }
    return {};
  }, [palm]);

  // const showLabels = useMemo(() => {
  //   return labels
  //     .filter((a) => a.Name)
  //     .map((a) => {
  //       return (
  //         <li className="blend-shapes-item">
  //           <span className="face-shapes-label">{a.Name}</span>
  //           <span
  //             className="blend-shapes-value"
  //             style={{
  //               width: `calc(${+a.Confidence}% - 120px)`,
  //             }}
  //           >
  //             {a.Confidence.toFixed(4)}
  //           </span>
  //         </li>
  //       );
  //     });
  // }, [labels]);

  return (
    <Grid maxWidth="sm" sx={{ marginTop: 0 }}>
      {
        showModal ? 
          <ScoreModal open={true} onClose={() => setModal(false)} children={
            <>
              <div>In this session stream, spoofing was detected in {((spoofs.current.length / totalFrames.current) * 100).toFixed(2)}% of the frames</div>
              <div>
                <video autoPlay src={Memory.sessionVudeoUrl} style={{
                  width: 650,
                  height: 400
                }}></video>
              </div>
            </>
          } />
        : null
      }

      <Box sx={{ padding: 0, fontSize: "2rem" }}>
        {face != null ? (
          <Typography variant="h5">
            <strong style={{ paddingRight: 20 }}>Face Match:</strong>
              <span style={faceStyle}>{face.toFixed(2)}%</span>
          </Typography>
        ) : (
          <Typography variant="h5">
            <strong style={{ paddingRight: 5 }}>Rtc Status:</strong>{" "}
            <span style={faceStyle}>{webrtStatus}</span>
          </Typography>
        )}
        <Typography variant="h5">
          {showPalmMatch ? (
            <>
              <strong style={{ paddingRight: 20 }}>Palm Match:</strong>
              <span style={palmStyle}>{palm.toFixed(2)}%</span>
            </>
          ) : null}
          
          {/* {labels?.length ? 
            <div>
              <ul className="blend-shapes-list" id="face-blend-shapes">
                {showLabels}
              </ul>
            </div>
          : null} */}
        </Typography>
      </Box>
    </Grid>
  );
};
